<template>
  <div class="min-h-full flex flex-col">
    <div>
      <div class="flex justify-between items-center" :class="{'mb-10': !isPitch}">
        <div class="flex flex-col">
          <h4 class="text-3xl sm:text-4xl font-bold leading-tight">
            {{ isPitch ? 'Pitch' : 'Introduce yourself' }}
          </h4>
          <div v-if="!isPitch" class="mt-6 sm:mt-10 text-gray-500 font-light text-xl">
            Tell <span class="font-semibold">{{ show.name }}</span> why you think you would be a great guest on their show.
          </div>
        </div>
        <!-- Step counter -->
        <StepsCounter class="ml-8" :class="{'mb-32': !isPitch}" v-if="steps.length" :stepCount="steps.length" :currentStep="currentStep"/>
      </div>
    </div>

    <div v-if="isPitch" class="mt-13">
      <p class="text-black font-light mb-2.5">What booking option are you pitching for?</p>
      <select name="showOptionPrices" id="showOptionPrices" class="form-select guestio-form-input mb-16" v-model="pitchBookingOptionPriceId" placeholder="Booking options" @change="setPitchBookinOption">
        <template v-for="(option, index) in showOptionPrices">
          <template v-if="option.prices.length > 1">
            <option
              v-for="(price, priceIndex) in option.prices"
              :value="price.id"
              :key="`price-option-${priceIndex}`"
            >
              {{ option.name }} - {{ price.interview_duration }} min
            </option>
          </template>

          <template v-else>
            <option
              :value="option.prices[0].id"
              :key="`option-${index}`"
            >
              {{ option.name }} {{ option.prices[0].interview_duration > 0 ? ` - ${option.prices[0].interview_duration} min` : '' }}
            </option>
          </template>
        </template>
      </select>
      <p class="text-black font-light">Why you think you would be a great guest on their show</p>
    </div>

    <div>
      <ValidationObserver ref="pitchForm" v-slot="{ handleSubmit }" tag="div">
        <form method="post" @submit.prevent="handleSubmit(savePitch)">
          <div>
            <label class="sr-only" for="pitch_body">Pitch Body</label>
            <ValidationProvider name="Pitch Body" vid="pitch_body" v-slot="{ errors }" tag="div">
              <div class="mt-2">
                <textarea rows="5" class="form-input text-black bg-gray-50 w-full px-5 py-3 rounded-xl border-transparent focus:bg-white" v-model="form.body"></textarea>
              </div>
              <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
            </ValidationProvider>
          </div>

          <div v-if="! form.media" class="mt-4 flex items-center space-x-4">
            <modal :with-close-button="false" class="bg-pink-500 text-xs text-blue-800 bg-opacity-10 flex items-center px-6 py-3 rounded-full hover:bg-opacity-20 focus:shadow-outline">
              <VideoIcon class="text-pink-500 h-5" />
              <span class="ml-3">Record a video</span>

              <template #content="{ close }">
                <div v-if="userIsPro" class="max-w-xl w-full bg-white rounded-xl overflow-auto my-4 p-6 lg:px-8 max-h-screen-10">
                  <RecordVideoPitch @close="close" @update-pitch="updatePitch" :media="form.media" />
                </div>

                <ProFeatureModal v-else @close="close">
                  To record video and audio messages, you need a PRO subscription.
                </ProFeatureModal>
              </template>
            </modal>

            <modal class="bg-pink-500 text-xs text-blue-800 bg-opacity-10 flex items-center px-6 py-3 rounded-full hover:bg-opacity-20 focus:shadow-outline">
              <MicIcon class="text-pink-500 h-5" />
              <span class="ml-3">Record an audio</span>

              <template #content="{ close }">
                <div v-if="userIsPro" class="max-w-xl w-full bg-white rounded-xl overflow-auto my-4 p-6 lg:px-8 max-h-screen-10">
                  <RecordAudioPitch @close="close" @update-pitch="updatePitch" :media="form.media" />
                </div>

                <ProFeatureModal v-else @close="close">
                  To record video and audio messages, you need a PRO subscription.
                </ProFeatureModal>
              </template>
            </modal>
          </div>

          <div v-if="form.media" class="mt-8">
            <div class="flex items-center justify-between">
              <h4 class="text-lg text-blue-800 text-medium">
                Your {{ form.media.collection_name === 'video_recordings' ? 'video' : 'audio' }} pitch
              </h4>

              <div class="flex items-center space-x-6">
                <modal ref="editModal" :with-close-button="false" class="text-pink-500 space-x-2 flex items-center text-sm">
                  <EditAltIcon class="h-5 w-5" />
                  <span>Edit</span>

                  <template #content="{ close }">
                    <div class="max-w-xl w-full bg-white rounded-xl overflow-auto my-4 p-6 lg:px-8 max-h-screen-10">
                      <RecordVideoPitch
                        v-if="form.media.collection_name === 'video_recordings'"
                        @close="close"
                        @update-pitch="updatePitch"
                        :media="form.media"
                      />
                      <RecordAudioPitch
                        v-else-if="form.media.collection_name === 'audio_recordings'"
                        @close="close"
                        @update-pitch="updatePitch"
                        :media="form.media"
                      />
                      <span v-else class="text-red-500 text-sm">
                        Unknown file type
                      </span>
                    </div>
                  </template>
                </modal>

                <ConfirmDialog
                  @confirm="deletePitch"
                  confirm-action="Yes, Delete"
                  dialog-title="Delete pitch recording?"
                  dialog-text="Are you sure you want to delete this pitch recording?"
                  class="text-red-500 flex items-center text-sm"
                >
                  <TrashIcon class="h-5 w-5" />
                  <span class="ml-2">Delete</span>
                </ConfirmDialog>
              </div>
            </div>
            <div class="mt-4">
              <VideoPreview v-if="form.media.collection_name === 'video_recordings'" :media-src="form.media.url" />
              <AudioPreview v-else-if="form.media.collection_name === 'audio_recordings'" :media-src="form.media.url" />
              <span v-else class="text-red-500 text-sm">
                Unknown file type
              </span>
            </div>
          </div>

          <div class="mt-12 flex items-center justify-center space-x-18">
            <button v-if="selectedBookingOption && selectedBookingOption.slug !== 'pitch'" type="button" @click.prevent="nextStep" class="uppercase text-violet font-semibold text-xl px-12">
              Skip
            </button>

            <button
              type="submit"
              class="rounded-full h-16 sm:h-20 bg-indigo-gradiant text-white text-xl font-bold flex justify-center items-center uppercase px-16 disabled:cursor-not-allowed disabled:opacity-50"
              :disabled="loading || (! form.body.length && ! form.media) || (isPitch && !pitchBookingOptionPriceId)"
            >
              <span v-if="loading">
                <loading-icon class="h-5 w-5"/>
              </span>
              <span v-else>Continue</span>
            </button>
          </div>

          <div v-if="currentStep > 2 || (currentStep == 2 && !isPitch)" class="text-center mt-4">
            <button @click.prevent="prevStep" type="button" class="text-sm text-pink-500 hover:underline">Previous step</button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { chain } from 'lodash'
  import StepsCounter from '@/components/StepsCounter';
  import EditAltIcon from '@/components/svgs/EditAltIcon';
  import ConfirmDialog from '@/components/shared/ConfirmDialog';
  import ProFeatureModal from '@/components/shared/ProFeatureModal';
  import VideoIcon from '@/components/svgs/VideoIcon';
  import TrashIcon from '@/components/svgs/TrashIcon';
  import MicIcon from '@/components/svgs/MicIcon';
  import Modal from '@/components/shared/Modal';
  import RecordVideoPitch from '@/components/Book/RecordVideoPitch';
  import RecordAudioPitch from '@/components/Book/RecordAudioPitch';
  import VideoPreview from '@/components/shared/VideoPreview';
  import AudioPreview from '@/components/shared/AudioPreview';

  export default {
    name: 'BookingPitch',
    components: {
      StepsCounter,
      ProFeatureModal,
      VideoIcon,
      MicIcon,
      TrashIcon,
      Modal,
      RecordVideoPitch,
      RecordAudioPitch,
      VideoPreview,
      AudioPreview,
      ConfirmDialog,
      EditAltIcon,
    },

    data() {
      return {
        loading: false,
        pitchBookingOptionPriceId: null,
        form: {
          body: '',
          media: null,
        }
      }
    },

    computed: {
      ...mapGetters({
        isPitch: 'showBooking/isPitch',
        steps: 'showBooking/enabledSteps',
        guest: 'showBooking/guest',
        pitch: 'showBooking/pitch',
        selectedBookingOption: 'showBooking/selectedBookingOption',
        bookingOptions: 'showBooking/bookingOptions',
        user: 'auth/getUser',
        show: 'shows/show',
      }),

      currentStep() {
        return this.$store.state.showBooking.currentStep
      },

      userIsPro() {
        return this.user.account_type == 'pro'
      },

      showOptionPrices() {
        return chain(this.bookingOptions)
          .filter(option => !['pitch', 'miniview', 'media-feature'].includes(option.slug))
          .map(option => {
            return {
              ...option,
              prices: this.show.option_prices.filter(price => price.booking_option_id === option.id)
            }
          })
          .filter(option => option.prices.length)
          .sortBy(option => option.id)
          .value()
      },
    },

    methods: {
      updatePitch(media) {
        this.form.media = media
      },

      deletePitch(callback) {
        this.form.media = null
        callback()
      },

      savePitch() {
        this.$store.commit('showBooking/setPitch', this.form)

        this.nextStep()
      },

      determineStepNumber() {
        let step = this.steps.findIndex(step => step.name == 'pitch')

        if (step === -1) {
          return this.$router.push({ name: this.steps[0].route })
        }

        this.$store.commit('showBooking/setCurrentStep', step + 1)
      },

      nextStep() {
        let nextStepRoute = this.steps[this.currentStep].route

        this.$router.push({name: nextStepRoute, params: { isPitch: this.isPitch }})
      },

      prevStep() {
        let prevStepRoute = this.steps[this.currentStep - 2].route

        this.$router.push({name: prevStepRoute, params: { isPitch: this.isPitch }})
      },

      setPitchBookinOption() {
        const price = this.show.option_prices.find(price => price.id === this.pitchBookingOptionPriceId);
        const option = this.bookingOptions.find(option => option.id === price.booking_option_id);

        this.$store.commit('showBooking/setSelectedBookingOption', {
          ...option,
          price: price,
          amount: 1,
        });
      },
    },

    mounted() {
      this.determineStepNumber()

      if (! this.guest || ! this.guest.id) {
        this.$router.push({name: this.steps[0].route})
        return
      }

      if (this.pitch && this.pitch.body) {
        this.form.body = this.pitch.body
      }

      if (this.pitch && this.pitch.media) {
        this.form.media = this.pitch.media
      }
    }
  }
</script>
